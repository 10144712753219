<template>
  <div class="detail">
    <el-container v-bind:style="{ height: Height + 'px' }">
      <el-main style="background: white; margin: 20px">
        <div class="main_box">
          <div class="bread">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item
                v-if="$route.query.webPage == '1'"
                :to="{
                  path: '/academic',
                  query: {
                    webPage: $route.query.webPage,
                  },
                }"
              >
                <span>中文期刊推荐</span>
              </el-breadcrumb-item>
              <el-breadcrumb-item
                v-if="$route.query.webPage == '2'"
                :to="{
                  path: '/academic',
                  query: {
                    webPage: $route.query.webPage,
                  },
                }"
              >
                <span>英文期刊推荐</span>
              </el-breadcrumb-item>
              <el-breadcrumb-item
                v-if="$route.query.webPage == '3'"
                :to="{
                  path: '/academic',
                  query: {
                    webPage: $route.query.webPage,
                  },
                }"
              >
                <span>个人中心</span>
              </el-breadcrumb-item>
              <el-breadcrumb-item
                v-if="$route.query.webPage == '4'"
                :to="{
                  path: '/academic',
                  query: {
                    webPage: $route.query.webPage,
                  },
                }"
              >
                <span>机构订阅</span>
              </el-breadcrumb-item>
              <el-breadcrumb-item
                :to="{
                  path: '/academic',
                  query: {
                    search: $route.query.search,
                    type: $route.query.type,
                    webPage: $route.query.webPage,
                  },
                }"
                v-if="$route.query.search"
                >期刊检索：“{{ $route.query.search }}”</el-breadcrumb-item
              >
              <el-breadcrumb-item
                v-if="
                  !$route.query.personalized && $route.query.webPage !== '4'
                "
                :to="{
                  path: '/journalsList',
                  query: {
                    subject: $route.query.subject,
                    language: dataAll.language,
                    webPage: $route.query.webPage,
                  },
                }"
                >{{ $route.query.subject }}期刊</el-breadcrumb-item
              >
              <el-breadcrumb-item
                v-if="!$route.query.personalized && $route.query.webPage == '4'"
                :to="{
                  path: '/journalsList',
                  query: {
                    subject: $route.query.subject,
                    language: 2,
                    webPage: $route.query.webPage,
                  },
                }"
                >{{ $route.query.subject }}期刊</el-breadcrumb-item
              >
              <el-breadcrumb-item
                v-if="$route.query.personalized"
                :to="{
                  path: '/journalsList',
                  query: {
                    subject: $route.query.subject,
                    language:
                      $route.query.webPage == 4 ? '2' : dataAll.language,
                    webPage: $route.query.webPage,
                  },
                }"
                >{{ $route.query.subject }}期刊</el-breadcrumb-item
              >
              <el-breadcrumb-item v-if="dataAll.language == '0'">{{
                dataAll.cn_name
              }}</el-breadcrumb-item>
              <el-breadcrumb-item v-if="dataAll.language == '1'">{{
                dataAll.name
              }}</el-breadcrumb-item>
            </el-breadcrumb>
            <!-- <router-link :to="{ path: `/academic`, query: { webPage: num_ } }"
              > -->
              <el-button type="primary" size="mini" plain @click="goAcademic"
                >返回</el-button
              >
              <!-- </router-link
            > -->
          </div>
          <!-- <el-divider></el-divider> -->
          <div class="box">
            <div class="box_top">
              <div class="journal_img">
                <div class="imges">
                  <img
                    v-if="dataAll.language"
                    :src="
                      'https://push.newacademic.net/cover/journal_cover/' +
                      (dataAll.language == '1'
                        ? dataAll.name
                        : dataAll.cn_name) +
                      '.jpg'
                    "
                    alt=""
                  />
                  <img
                    v-if="$route.query.personalized == 1"
                    class="subscribe_css"
                    src="../../../assets/imgs/icons/customized1.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="journal_data">
                <div class="title">
                  <div>
                    <h2 v-if="dataAll.language == 1">
                      {{ dataAll.name }}
                    </h2>
                    <h2 v-if="dataAll.language == 0">
                      {{ dataAll.cn_name }}
                    </h2>
                  </div>
                  <el-button
                    type="primary"
                    size="mini"
                    plain
                    v-if="dataAll.isSubscribe == '0'"
                    @click="subscribe(dataAll)"
                    >订阅</el-button
                  >
                  <el-popconfirm
                    title="确认取消订阅吗？"
                    v-if="dataAll.isSubscribe == '1'"
                    @confirm="subscribe(dataAll)"
                  >
                    <el-button
                      type="primary"
                      size="mini"
                      plain
                      slot="reference"
                      style="background: #b14a75; color: white"
                      >已订阅</el-button
                    >
                  </el-popconfirm>
                </div>

                <div class="tags">
                  <div v-for="(item, index) in tags" :key="index">
                    <el-tag v-if="item == 'CA 化学文摘(美)(2020)'">CA</el-tag>
                    <el-tag
                      v-if="
                        item == 'CSCD 中国科学引文数据库来源期刊(2021-2022年度)'
                      "
                      type="success"
                      >CSCD</el-tag
                    >
                    <el-tag
                      v-if="
                        item ==
                        'CSCD 中国科学引文数据库来源期刊(2021-2022年度)(扩展版)'
                      "
                      type="info"
                      >CSCD(扩)</el-tag
                    >
                    <el-tag
                      v-if="
                        item == 'CSSCI 中文社会科学引文索引(2021-2022)来源期刊'
                      "
                      type="warning"
                      >CSSCI</el-tag
                    >
                    <el-tag v-if="item == 'CSTPCD'" type="danger"
                      >CSTPCD</el-tag
                    >
                    <el-tag
                      v-if="item == 'JST 日本科学技术振兴机构数据库(日)(2018)'"
                      >JST</el-tag
                    >
                    <el-tag
                      v-if="item == 'Pж(AJ) 文摘杂志(俄)(2020)'"
                      type="success"
                      >Pж(AJ)
                    </el-tag>
                    <el-tag v-if="item == 'SA 科学文摘(英)(2020)'" type="info"
                      >SA</el-tag
                    >
                    <el-tag
                      v-if="
                        item ==
                        'WJCI 科技期刊世界影响力指数报告（2020）来源期刊'
                      "
                      type="warning"
                      >WJCI</el-tag
                    >
                    <el-tag v-if="item == '北大核心'" type="danger"
                      >北大核心</el-tag
                    >
                    <el-tag
                      v-if="item == 'EI 工程索引(美)(2020)'"
                      type="warning"
                      >EI</el-tag
                    >
                    <el-tag
                      v-if="item == 'SCI 科学引文索引(美)(2020)'"
                      type="danger"
                      >SCI</el-tag
                    >
                  </div>
                </div>
                <p><span class="tit">创办单位：</span>{{ dataAll.press }}</p>
                <!-- <p><span class="tit">ISSN：</span>{{ dataAll.issn }}</p> -->
                <p>
                  <!-- <span><span class="tit">出版社：</span>{{ dataAll.press }}</span> -->
                  <!-- <span
                    ><span class="tit">期次：</span
                    >{{ dataAll.zh_serial_number }}</span
                  > -->
                  <span><span class="tit">ISSN：</span>{{ dataAll.issn }}</span>
                  <span
                    ><span class="tit">出版周期：</span
                    >{{ dataAll.frequency }}</span
                  >
                </p>
                <!-- <p></p> -->
                <p>
                  <span
                    ><span class="tit">学科：</span>{{ dataAll.subject }}</span
                  >
                  <span
                    ><span class="tit">最新更新时间：</span
                    >{{ dataAll.update_time | parseTime("{y}-{m}-{d}") }}</span
                  >
                </p>
                <p
                  v-if="dataAll.introduce !== '' && dataAll.introduce"
                  style="display: flex"
                >
                  <span class="tit" style="display: inline-block; width: 80%"
                    >期刊简介：</span
                  >
                  <span v-if="dataAll.introduce.length <= 150">
                    {{ dataAll.introduce }}
                  </span>
                  <span
                    style="display: inline-block; width: 80%"
                    v-if="dataAll.introduce.length > 150 && !introduceAll"
                    >{{ dataAll.introduce.slice(0, 150) }}
                    <span class="more_text" @click="moreText01()"
                      >...更多</span
                    ></span
                  >
                  <span
                    style="display: inline-block; width: 80%"
                    v-if="dataAll.introduce.length > 150 && introduceAll"
                    >{{ dataAll.introduce }}
                    <span class="more_text" @click="moreText01()"
                      >收起</span
                    ></span
                  >
                </p>
                <!-- <p></p> -->
                <p>
                  <!-- <span
                    ><span class="tit">创建时间：</span
                    >{{ dataAll.create_time | parseTime("{y}-{m}-{d}") }}</span
                  > -->
                </p>
                <!-- <p></p> -->
              </div>
            </div>
            <div class="box_issue">
              <div class="issue">
                <el-cascader-panel
                  v-model="dimensionArr"
                  :options="options"
                  :props="{ checkStrictly: true }"
                  @change="chosen(dimensionArr)"
                ></el-cascader-panel>
              </div>
              <!-- :style="dataAll.language == '1'? 'min-width:800px':'min-width:1000px'" -->
              <div
                class="articleList"
                v-loading="loading"
                element-loading-text="加载中"
                element-loading-spinner="el-icon-loading"
              >
                <div class="article_list_title" v-if="article.length > 0">
                  <p
                    style="
                      text-align: center;
                      line-height: 60px;
                      font-size: 18px;
                    "
                  >
                    {{ titleTime }}
                    <span
                      class="imgs01"
                      v-if="!switch_or && dataAll.language == '1'"
                      s
                      @click="switch_show"
                      ><img
                        src="../../../assets/imgs/icons/操作-翻译 (1).png"
                        alt=""
                    /></span>
                    <span
                      class="imgs02"
                      v-if="switch_or && dataAll.language == '1'"
                      @click="switch_show"
                      ><img
                        src="../../../assets/imgs/icons/操作-翻译.png"
                        alt=""
                    /></span>
                  </p>
                </div>
                <div
                  class="article_list"
                  v-for="(item, index) in article"
                  :key="index"
                >
                  <div class="title">
                    <div>
                      <h2 v-if="dataAll.language == '0'" @click="goEssay(item)">
                        {{ item._source.ch_title }}
                      </h2>
                      <h2 v-if="dataAll.language == '1'" @click="goEssay(item)">
                        {{ item._source.title }}
                      </h2>
                    </div>
                    <div v-if="item._source.ispdf == 1">
                      <el-button
                        size="mini"
                        style="background: #b14a75; color: white"
                        >已传递</el-button
                      >
                    </div>
                    <!-- <div>
                      <el-button
                        type="primary"
                        size="mini"
                        plain
                        @click="detail(item._id)"
                        >详情</el-button
                      >
                    </div> -->
                  </div>
                  <p
                    class="switch_en"
                    v-if="
                      switch_or &&
                      dataAll.language == '1' &&
                      item._source.ch_title !== ''
                    "
                  >
                    {{ item._source.ch_title }}
                  </p>
                  <p v-if="item._source.author.length > 0">
                    <!-- <img
                      src="../../../assets/imgs/icons/photo.png"
                      alt=""
                    /> -->
                    <span
                      v-for="(item_author, index) in item._source.author.slice(
                        0,
                        3
                      )"
                      :key="index"
                      >{{ item_author }} ；</span
                    >
                    <!-- <span v-if="item._source.author.length > 3">...</span> -->
                  </p>
                  <p
                    v-if="
                      item._source.keyword.length > 0 && dataAll.language == '1'
                    "
                  >
                    <!-- <img
                      src="../../../assets/imgs/icons/journalsci.png"
                      alt=""
                    /> -->
                    <span
                      v-for="(item_keyword, index) in item._source.keyword"
                      :key="index"
                      >{{ item_keyword }} ；</span
                    ><span v-if="item._source.keyword.length > 3">...</span>
                  </p>
                  <p
                    v-if="
                      item._source.ch_keyword.length > 0 &&
                      dataAll.language == '0'
                    "
                  >
                    <!-- <img
                      src="../../../assets/imgs/icons/journalsci.png"
                      alt=""
                    /> -->
                    <span
                      v-for="(item_keyword, index) in item._source.ch_keyword"
                      :key="index"
                      >{{ item_keyword }} ；</span
                    >
                    <!-- <span v-if="item._source.ch_keyword.length > 3">...</span> -->
                  </p>
                  <!-- <p v-if="item._source.abstract.length > 0 && dataAll.language == '1'">
                    <img src="../../../assets/imgs/icons/文本摘要.png" alt="" />
                    <span
                      v-if="
                        item._source.abstract.length > 100 && one !== index
                      "
                      >{{ item._source.abstract.slice(0, 100) }}
                      <span class="more_text" @click="moreText(index)"
                        >...更多</span
                      ></span
                    >
                  </p>
                  <p v-if="item._source.ch_abstract.length > 0 && switch_or && dataAll.language == '1'" >
                    <img src="../../../assets/imgs/icons/文本摘要.png" alt="" />
                    <span
                      v-if="
                        item._source.ch_abstract.length > 100 && one !== index
                      "
                      >{{ item._source.ch_abstract.slice(0, 100) }}
                      <span class="more_text" @click="moreText(index)"
                        >...更多</span
                      ></span
                    >
                  </p>
                  
                  <p v-if="item._source.abstract.length > 0 && one == index">
                    <span v-if="more"
                      >{{ item._source.abstract }}
                      <span class="more_text" @click="moreText">
                        收起</span
                      ></span
                    >
                  </p>
                    <p v-if="item._source.ch_abstract.length > 0 && one == index">
                    <span v-if="more"
                      >{{ item._source.ch_abstract }}
                      <span class="more_text" @click="moreText">
                        收起</span
                      ></span
                    >
                  </p> -->
                  <!-- <el-divider></el-divider> -->
                </div>
                <div class="page-bar" v-if="totalCount > 10">
                  <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="totalCount > 10000 ? 10000 : totalCount"
                    @current-change="handleCurrentChange"
                    :page-size="10"
                    :current-page.sync="currentPage1"
                  >
                  </el-pagination>
                </div>
              </div>
            </div>
            <!-- <div class="box_left">
                  <div class="Information">
                      
                  </div>
                  <div class="issue">

                  </div>
              </div>
              <div class="box_right"></div> -->
          </div>
          <el-dialog
            title="文章详情"
            :visible.sync="dialogVisible"
            :width="'650px'"
          >
            <div v-if="articleDetail !== ''" class="details">
              <div class="title">
                <div>
                  <h2 v-if="dataAll.language == '0'">
                    {{ articleDetail._source.ch_title }}
                  </h2>
                  <h2 v-if="dataAll.language == '1'">
                    {{ articleDetail._source.title }}
                  </h2>
                </div>
                <div>
                  <img
                    v-if="articleDetail._source.isCollect == 0"
                    @click="collection(articleDetail)"
                    src="../../../assets/imgs/icons/collect.png"
                    alt=""
                  />
                  <img
                    v-if="articleDetail._source.isCollect == 1"
                    @click="collection(articleDetail)"
                    src="../../../assets/imgs/icons/collect1.png"
                    alt=""
                  />
                </div>
              </div>
              <p>
                <img src="../../../assets/imgs/icons/photo.png" alt="" /><span
                  v-for="(item_author, index) in articleDetail._source.author"
                  :key="index"
                  >{{ item_author }} ；</span
                >
              </p>
              <p v-if="articleDetail._source.ch_keyword.length > 0">
                <img
                  src="../../../assets/imgs/icons/journalsci.png"
                  alt=""
                /><span
                  v-for="(item_keyword, index) in articleDetail._source
                    .ch_keyword"
                  :key="index"
                  >{{ item_keyword }} ；</span
                >
              </p>
              <p>
                <span>{{ articleDetail._source.ch_abstract }} </span>
              </p>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible = false"
                >确 定</el-button
              >
            </span>
          </el-dialog>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import Vue from "vue";
import {
  journal_detail,
  volume_period,
  article_list,
  article_detail,
  more_past,
  pdf_link,
  reads,
  subscribe_subject,
  cancel_subscribe,
  article_details,
  cancel_collect,
  collect,
  update_article,
} from "@/Api/journals";
export default {
  // inject: ["reloads"],
  provide() {
    return {
      reloading: this.reloading,
      imgs: require("../../../assets/imgs/icons/杂志期刊.svg"),
      // Height: 0,
    };
  },
  data() {
    return {
      Height: 0,
      keys: "",
      isRouterAlive: true,
      parameter: {},
      dataAll: {},
      tags: [],
      issue: [],
      options: [],
      options_copy: [],
      dimensionArr: [], // 默认选中的部分
      article: [],
      more: false,
      totalCount: 0,
      journalArticle: {},
      dialogVisible: false,
      articleDetail: "",
      webpage: window.sessionStorage.getItem("webPage"),
      article_id: "",
      total: 0,
      one: "",
      pages: 1,
      currentPage1: 1,
      introduceAll: false,
      titleTime: "",
      switch_or: true,
      loading: true,
      subscribeOr: localStorage.getItem("subscribeOr"),
      num_: 1,
    };
  },
  mounted() {
    //动态设置内容高度 让footer始终居底   header+footer的高度是190
    // console.log(this.name)
    this.Height = document.documentElement.clientHeight - 80; //监听浏览器窗口变化
    window.onresize = () => {
      this.Height = document.documentElement.clientHeight - 80;
    };
  },
  created() {
    if (this.$route.query.webPage) {
      this.keys = this.$route.query.webPage.toString();
    }
    this.parameter = this.$route.query;
    Vue.set(this.parameter, "id", this.$route.query.id);
    Vue.set(this.parameter, "user_openid", localStorage.getItem("code"));
    this.getData();
    this.update();
    if (sessionStorage.getItem("academic_num")) {
      this.num_ = sessionStorage.getItem("academic_num") - 0;
    }
  },
  watch: {
    $route(to, from) {
      if (to.query.webPage) {
        this.keys = to.query.webPage.toString();
      }
    },
  },
  methods: {
    reloading() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    handleSelect(key) {
      // console.log( key);
      this.keys = key;
      // window.sessionStorage.setItem("webPage", this.keys);

      let path = this.$route.path;
      // console.log(path);
      if (path !== "/academic") {
        this.$router.push({
          name: "Academic",
          query: { webPage: key },
        });
      }
      // if (this.keys == 1) {
      //   this.reloads();
      // }
      // if (this.keys == 2) {
      //   this.reloading();
      // }
    },
    update() {
      let user_id = {
        user_openid: localStorage.getItem("code"),
      };
      update_article(user_id)
        .then((res) => {
          // console.log("id", res);
          this.total = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getData() {
      journal_detail(this.parameter)
        .then((res) => {
          // console.log(res);
          this.dataAll = res.data.data[0];
          this.tags = this.dataAll.source.split(";;");
          this.getIssue();
          //   console.log(this.dimensionArr);
          // this.articleData();
          let that = this;
          // if (that.dataAll.language == "1") {
          setTimeout(function () {
            that.articleData();
          }, 1000);
          // } else {
          //   setTimeout(function () {
          //     that.articleData();
          //   }, 250);
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getIssue() {
      let id = {
        journal_id: this.parameter.id,
      };
      volume_period(id)
        .then((res) => {
          // console.log(res);
          //   console.log(res.data.data.year.buckets);
          this.options_copy = res.data.data.year.buckets;
          if (this.dataAll.language == "0") {
            res.data.data.year.buckets.forEach((item) => {
              let iss = {
                value: item.key,
                label: item.key + "年",
                children: [],
              };
              item.vol.buckets[0].issue.buckets.forEach((item_iss) => {
                let is = {
                  value: item_iss.key,
                  label: "第" + item_iss.key + "期",
                };
                iss.children.push(is);
              });
              this.options.push(iss);
            });
            this.dimensionArr = [
              this.options[0].value,
              this.options[0].children[0].value,
              //   this.options[0].children[0].children[0].value,
            ];
          }
          if (this.dataAll.language == "1") {
            res.data.data.year.buckets.forEach((item) => {
              let iss = {
                value: item.key,
                label: item.key + "年",
                children: [],
              };
              item.vol.buckets.forEach((item_iss) => {
                let is = {
                  value: item_iss.key,
                  label: "第" + item_iss.key + "卷",
                  children: [],
                };
                item_iss.issue.buckets.forEach((item_is) => {
                  let i = {
                    value: item_is.key,
                    label: "第" + item_is.key + "期",
                  };
                  is.children.push(i);
                });
                iss.children.push(is);
              });
              //   item.vol.buckets[0];
              this.options.push(iss);
            });
            // console.log(this.options);
            this.dimensionArr = [
              this.options[0].value,
              this.options[0].children[0].value,
              this.options[0].children[0].children[0].value,
            ];
          }

          //   console.log(this.dimensionArr);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    chosen(arr) {
      // console.log(arr);
      // console.log(arr.length);
      if (arr.length == 1) {
        // console.log("1");
        this.options.forEach((item) => {
          if (item.value == arr[0]) {
            if (this.dataAll.language == "1") {
              this.dimensionArr = [
                item.value,
                item.children[0].value,
                item.children[0].children[0].value,
              ];
            } else {
              this.dimensionArr = [item.value, item.children[0].value];
            }
          }
        });
      }
      if (arr.length == 2) {
        // console.log("2");
        this.options.forEach((item) => {
          if (item.value == arr[0]) {
            if (this.dataAll.language == "1") {
              // console.log(item);
              item.children.forEach((iten02, index) => {
                if (iten02.value == arr[1]) {
                  this.dimensionArr = [
                    item.value,
                    item.children[index].value,
                    item.children[index].children[0].value,
                  ];
                  // console.log(this.dimensionArr);
                }
              });
            } else {
              this.dimensionArr = [item.value, arr[1]];
            }
          }
        });
      }
      if (arr.length == 3) {
        // console.log("3");
        this.options.forEach((item) => {
          if (item.value == arr[0]) {
            if (this.dataAll.language == "1") {
              this.dimensionArr = [arr[0], arr[1], arr[2]];
            }
          }
        });
      }

      //   this.dimensionArr = ["2020", "92", "4"];
      // console.log(this.dimensionArr);
      this.pages = 1;
      this.currentPage1 = 1;
      this.articleData();
    },
    articleData() {
      // article
      // console.log(this.dimensionArr);
      if (this.dataAll.language == "1") {
        this.journalArticle = {
          journal_id: this.parameter.id,
          year: this.dimensionArr[0],
          vol: this.dimensionArr[1],
          issue: this.dimensionArr[2],
          page: this.pages,
          size: 10,
          user_openid: localStorage.getItem("code"),
        };
        this.titleTime =
          this.dimensionArr[0] +
          "年第" +
          this.dimensionArr[1] +
          "卷第" +
          this.dimensionArr[2] +
          "期";
      }
      if (this.dataAll.language == "0") {
        const exists = this.options_copy.some(
          (item) =>
            item.key === this.dimensionArr[0] &&
            item.vol?.buckets?.[0]?.key !== ""
        );

        // console.log("exists", exists);
        this.journalArticle = {
          journal_id: this.parameter.id,
          year: this.dimensionArr[0],
          vol: exists ? "1" : "",
          issue: this.dimensionArr[1],
          page: this.pages,
          size: 10,
          user_openid: localStorage.getItem("code"),
        };
        this.titleTime =
          this.dimensionArr[0] + "年第" + this.dimensionArr[1] + "期";
      }

      article_list(this.journalArticle)
        .then((res) => {
          // console.log(res);
          this.loading = false;
          this.article = res.data.data.hits;
          this.totalCount = res.data.data.total.value;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 订阅期刊
    subscribe(state) {
      let id = {
        user_openid: localStorage.getItem("code"),
        journal_id: state.id,
      };
      if (state.isSubscribe == "0") {
        subscribe_subject(id)
          .then((res) => {
            // console.log(res);
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.getData();
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (state.isSubscribe == "1") {
        cancel_subscribe(id)
          .then((res) => {
            // console.log(res);
            this.$message({
              message: "订阅已取消",
              //   type: "success",
            });
            this.getData();
          })
          .catch((err) => {
            console.log(err);
          });
      }
      //   this.getData();
    },
    // 期刊文章详情
    detail(id) {
      let articleId = {
        article_id: id,
        // article_id: "02154ba886e32bec79298f1b691aa35f",
        user_openid: localStorage.getItem("code"),
        // user_openid:'bfb6cccff1014a4aaadc1403479ba050'
      };
      article_details(articleId)
        .then((res) => {
          // console.log(res);
          this.articleDetail = res.data.data.hits[0];
        })
        .catch((err) => {
          console.log(err);
        });
      this.dialogVisible = true;
      this.article_id = id;
    },
    // 文章收藏
    collection(state) {
      let id = {
        user_openid: localStorage.getItem("code"),
        article_id: state._id,
      };
      if (state._source.isCollect == 0) {
        collect(id)
          .then((res) => {
            // console.log(res);
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.detail(this.article_id);
            // console.log(this.articleDetail)
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (state._source.isCollect == 1) {
        cancel_collect(id)
          .then((res) => {
            // console.log(res);
            this.$message({
              message: "收藏已取消",
              //   type: "success",
            });
            this.detail(this.article_id);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      //   this.getList();
    },
    // 翻页
    handleCurrentChange(val) {
      this.pages = val;
      this.currentPage1 = val;
      this.articleData();
      //   window.scrollTo(0, 0);
    },
    moreText(index) {
      this.more = !this.more;
      if (this.more) {
        this.one = index;
      } else {
        this.one = "";
      }
    },
    moreText01() {
      this.introduceAll = !this.introduceAll;
    },
    goEssay(data) {
      if (this.dataAll.cn_name !== "" && this.dataAll.cn_name !== null) {
        this.$router.push({
          path: "/essay",
          query: {
            id: data._id,
            journal: this.dataAll.cn_name,
            type: this.$route.query.subject,
            journal_id: this.$route.query.id,
            period: this.titleTime,
            ispdf: data._source.ispdf,
            webPage: this.$route.query.webPage,
          },
        });
      } else {
        this.$router.push({
          path: "/essay",
          query: {
            id: data._id,
            journal: this.dataAll.name,
            type: this.$route.query.subject,
            journal_id: this.$route.query.id,
            period: this.titleTime,
            ispdf: data._source.ispdf,
            webPage: this.$route.query.webPage,
          },
        });
      }
    },
    switch_show() {
      this.switch_or = !this.switch_or;
    },
    goAcademic() {
      this.$router.push({
        path: "/academic",
        query: { webPage: this.num_ },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.detail {
  min-width: 1360px;

  .el-aside {
    background-color: #fff;
    color: #333;
    text-align: center;
    line-height: 200px;
    // display: inline-block;
  }

  .el-main {
    // height: 100%;
    background-color: #e9eef3;
    color: #333;
    // text-align: center;
    // line-height: 160px;
  }

  body > .el-container {
    margin-bottom: 40px;
  }

  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }

  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }

  .el-menu {
    background-color: #fff;
  }
}

.is-active div {
  background-color: #b14a75;

  span {
    color: #fff;
  }

  i {
    color: #fff;
  }
}

.el-menu-item {
  padding: 5px 50px !important;
  background-color: #fff;
  margin-bottom: 5px;
}

.aside_item {
  width: 100%;
  height: 50px;
  line-height: 50px;
  border-radius: 5px;

  span {
    color: #b1aec8;
    font-size: 15px;
  }
}

.item {
  line-height: 30px;

  /deep/ .el-badge__content.is-fixed {
    position: absolute;
    top: 5px;
    right: 13px;
    transform: translateY(-50%) translateX(100%);
  }
}

.main_box {
  min-width: 1200px;
  max-width: 1550px;
  margin: auto;

  padding: 0px 60px 0 60px;

  // min-width: 1200pxs;
  .bread {
    // line-height: 50px;
    min-width: 1200pxs;
    display: flex;
    justify-content: space-between;

    .el-breadcrumb {
      line-height: 28px;
    }
  }

  .box {
    min-width: 1200px;
    max-width: 1550px;
    margin: auto;

    .box_top {
      margin: 20px 0;
      display: flex;
      padding: 0 50px;

      .journal_img {
        margin-right: 20px;

        img {
          width: 150px;
          height: 205px;
          border-radius: 5px;
        }
      }

      .journal_data {
        width: 80%;

        h2:hover {
          color: #a0a0a0;
        }

        .title {
          margin-left: 30px;
        }

        .tags {
          display: flex;
          margin: 10px 0 10px 30px;

          .el-tag {
            margin-right: 10px;
          }
        }

        p {
          line-height: 30px;

          span {
            display: inline-block;
            width: 50%;

            span {
              display: inline-block;
              //   text-align: right;
              width: 100px;
            }
          }
        }
      }
    }

    .box_issue {
      //   margin: 20px 0;
      display: flex;
      padding: 20px 50px;

      .issue {
        // width: 360px;
        margin-right: 50px;

        .el-cascader-panel {
          min-width: 300px;
          max-width: 450px;

          /deep/ .el-cascader-menu {
            min-width: 150px;
          }
        }
      }

      .articleList {
        width: 100%;

        .article_list_title {
          margin-bottom: 10px;
          // padding: 10px;
          border-radius: 5px;
          background: #eeeeee;

          img {
            width: 30px;
            position: relative;
            top: 15px;
          }

          .imgs01 {
            float: right;
          }

          .imgs02 {
            float: right;
          }

          .imgs01:hover img {
            cursor: pointer;
            content: url("../../../assets/imgs/icons/操作-翻译.png");
          }

          .imgs02:hover img {
            cursor: pointer;
            content: url("../../../assets/imgs/icons/操作-翻译 (1).png");
          }
        }

        .article_list {
          //   margin: 20px 0;
          margin-bottom: 10px;
          padding: 10px;
          border-radius: 5px;
          background: #eeeeee;

          .title {
            width: 100%;
            // min-width: 300px;
            display: flex;

            justify-content: space-between;

            h2 {
              line-height: 32px;
            }

            img {
              width: 30px;
              cursor: pointer;
            }

            h2:hover {
              color: rgb(182, 182, 182);
              cursor: pointer;
            }

            h2 {
              font-size: 18px;
              font-weight: 500;
            }

            p {
              line-height: 30px;
              margin: 10px;
              word-wrap: break-word;

              img {
                width: 30px;
                position: relative;
                top: 10px;
                margin-right: 10px;
              }

              span {
                display: inline-block;
                line-height: 30px;
                //   text-indent:2em;
              }
            }

            .abstract {
              text-indent: 2em;
              overflow: hidden;
              -webkit-line-clamp: 2.5;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }
  }

  .page-bar {
    margin: 10px 0;
    display: flex;
    justify-content: center;
  }

  /deep/ .el-radio {
    display: table;
    width: 80%;
    height: 100%;
    position: absolute;
    color: red;

    .el-radio__input {
      visibility: hidden;
    }
  }

  /deep/ .el-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /deep/ .el-cascader-menu__wrap {
    height: 500px;
  }

  .title {
    display: flex;
    justify-content: space-between;
  }

  .details {
    .title {
      display: flex;
      justify-content: space-between;

      img {
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
    }
  }

  h2 {
  }

  p {
    line-height: 30px;
    margin: 10px;

    img {
      width: 30px;
      position: relative;
      top: 10px;
      margin-right: 10px;
    }

    span {
      display: inline-block;
      line-height: 30px;
      //   text-indent:2em;
    }
  }
}

.tit {
  width: 100px !important;
  text-align: right;
}

.more_text {
  font-weight: 700;
}

.more_text:hover {
  color: #9be0fc;
  cursor: pointer;
}

.switch_en {
  margin: 0 !important;
}

.subscribe_css {
  width: 50px !important;
  height: 50px !important;
  position: relative;
  left: -50px;
  //   top: -150px;
}

.imges {
  display: flex;
}
</style>